import { ChangeEvent, FormEvent, useRef, useState } from 'react';
import { ISwaSwtCircuit } from '../interfaces/ISwaSwtCircuit';
import { Grid, TextField, Button, Box } from '@mui/material';
import { IPV4MaskInput, Ipv4CidrRegex, Ipv4MultilineRegex } from '../utils/MaskUtils';
import FormGroups from './FormGroups';

type Props = {
  installation?: ISwaSwtCircuit,
  onSave: (installation: ISwaSwtCircuit) => void;
};

const SwaSwtCircuitForm = ({ installation, onSave }: Props) => {
  const initialFormState = {
    id: installation?.id || '',
    cliente: installation?.cliente || '',
    ip: installation?.ip || '',
    ipGatewaySwa: installation?.ipGatewaySwa || '',
    porta: installation?.porta || '',
    ipv4LanSwt: installation?.ipv4LanSwt || '',
    ipv4WanSwt: installation?.ipv4WanSwt || '',
    ipv4LoopbackSwt: installation?.ipv4LoopbackSwt || '',
    cvlan: installation?.cvlan || '',
    gerenciaVlan: installation?.gerenciaVlan || '',
    rangeIp: installation?.rangeIp || '',
    ipv4LanSwa: installation?.ipv4LanSwa || '',
    ipv4WanSwa: installation?.ipv4WanSwa || '',
    ipv4LoopbackSwa: installation?.ipv4LoopbackSwa || '',
  };

  const inputRefs = useRef<Record<string, HTMLInputElement | null>>({});

  const setInputRef = (name: string) => (element: HTMLInputElement | null) => {
    inputRefs.current[name] = element;
  };

  const handleScrollIntoView = (fieldName: string) => {
    const inputRef = inputRefs.current[fieldName];
    if (inputRef) {
      inputRef.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });;
    } else {
      console.error(`Não foi possível encontrar o campo: ${fieldName}`);
    }
  };

  const [formState, setFormState] = useState(initialFormState);
  const [errors, setErrors] = useState<{ [key: string]: string; }>({});

  const validateField = (name: string, value: any) => {
    let error = '';

    const validators: { [key: string]: (value: any) => boolean; } = {
      ip: value => value !== '' ? Ipv4CidrRegex.test(value) : true,
      ipGatewaySwa: value => value !== '' ? Ipv4CidrRegex.test(value) : true,
      gerenciaVlan: value => value !== '' ? Ipv4CidrRegex.test(value) : true,
      ipv4LanSwt: value => value !== '' ? Ipv4CidrRegex.test(value) : true,
      ipv4WanSwt: value => value !== '' ? Ipv4CidrRegex.test(value) : true,
      ipv4LoopbackSwt: value => value !== '' ? Ipv4CidrRegex.test(value) : true,
      ipv4LanSwa: value => value !== '' ? Ipv4CidrRegex.test(value) : true,
      ipv4WanSwa: value => value !== '' ? Ipv4CidrRegex.test(value) : true,
      ipv4LoopbackSwa: value => value !== '' ? Ipv4CidrRegex.test(value) : true,
      rangeIp: value => value !== '' ? Ipv4MultilineRegex.test(value) : true,
      // ipv6LanNetworkAddress: value => value !== '' ? Ipv6CidrRegex.test(value) : true,
    };

    if (validators[name] && !validators[name](value)) {
      error = 'Formato inválido';
    }

    setErrors(prevErrors => ({ ...prevErrors, [name]: error }));
    return error === '';
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormState(prevState => ({ ...prevState, [name]: value }));
    validateField(name, value);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const formFields = { ...formState };

    let isValid = true;
    for (const [name, value] of Object.entries(formFields)) {
      if (!validateField(name, value)) {
        isValid = false;
        handleScrollIntoView(name);
      }
    }
    if (isValid) {
      onSave({
        id: installation?.id || formFields.id,
        cliente: formFields.cliente,
        ip: formFields.ip,
        ipGatewaySwa: formFields.ipGatewaySwa,
        porta: formFields.porta !== '' ? Number(formFields.porta) : undefined,
        ipv4LanSwt: formFields.ipv4LanSwt,
        ipv4WanSwt: formFields.ipv4WanSwt,
        ipv4LoopbackSwt: formFields.ipv4LoopbackSwt,
        cvlan: formFields.cvlan !== '' ? Number(formFields.cvlan) : undefined,
        gerenciaVlan: formFields.gerenciaVlan,
        rangeIp: formFields.rangeIp,
        ipv4LanSwa: formFields.ipv4LanSwa,
        ipv4WanSwa: formFields.ipv4WanSwa,
        ipv4LoopbackSwa: formFields.ipv4LoopbackSwa,
      });
    }
  };

  return (
    <Box component='form' onSubmit={handleSubmit} paddingTop={1}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name='id'
            label="ID Vantive"
            type='number'
            inputMode='numeric'
            inputProps={{ pattern: "[0-9]*" }}
            InputLabelProps={{
              shrink: true,
            }}
            value={formState.id}
            onChange={handleChange}
            fullWidth
            required
            disabled={!!installation}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='cliente'
            label="Cliente"
            value={formState.cliente}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='ip'
            label="IP"
            inputRef={setInputRef('ip')}
            value={formState.ip}
            onChange={handleChange}
            fullWidth
            InputProps={{
              inputComponent: IPV4MaskInput as any
            }}
            error={!!errors.ip}
            helperText={errors.ip}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='ipGatewaySwa'
            label="IP Gateway SWA"
            inputRef={setInputRef('ipGatewaySwa')}
            value={formState.ipGatewaySwa}
            onChange={handleChange}
            fullWidth
            InputProps={{
              inputComponent: IPV4MaskInput as any
            }}
            error={!!errors.ipGatewaySwa}
            helperText={errors.ipGatewaySwa}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='porta'
            label="Porta"
            type='number'
            inputMode='numeric'
            inputProps={{ pattern: "[0-9]*" }}
            InputLabelProps={{
              shrink: true,
            }}
            value={formState.porta}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <FormGroups title='SWT'>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  name='ipv4LanSwt'
                  label="IP de LAN"
                  inputRef={setInputRef('ipv4LanSwt')}
                  value={formState.ipv4LanSwt}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    inputComponent: IPV4MaskInput as any
                  }}
                  error={!!errors.ipv4LanSwt}
                  helperText={errors.ipv4LanSwt}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name='ipv4WanSwt'
                  label="IP de Wan"
                  inputRef={setInputRef('ipv4WanSwt')}
                  value={formState.ipv4WanSwt}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    inputComponent: IPV4MaskInput as any
                  }}
                  error={!!errors.ipv4WanSwt}
                  helperText={errors.ipv4WanSwt}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name='ipv4LoopbackSwt'
                  label="IP de Loopback"
                  inputRef={setInputRef('ipv4LoopbackSwt')}
                  value={formState.ipv4LoopbackSwt}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    inputComponent: IPV4MaskInput as any
                  }}
                  error={!!errors.ipv4LoopbackSwt}
                  helperText={errors.ipv4LoopbackSwt}
                  required
                />
              </Grid>
            </Grid>
          </FormGroups>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='cvlan'
            label="CVLAN"
            type='number'
            inputMode='numeric'
            inputProps={{ pattern: "[0-9]*" }}
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={setInputRef('cvlan')}
            value={formState.cvlan}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='gerenciaVlan'
            label="Gerencia VLAN"
            inputRef={setInputRef('gerenciaVlan')}
            value={formState.gerenciaVlan}
            onChange={handleChange}
            fullWidth
            InputProps={{
              inputComponent: IPV4MaskInput as any
            }}
            error={!!errors.gerenciaVlan}
            helperText={errors.gerenciaVlan}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name='rangeIp'
            label="Range IP"
            inputRef={setInputRef('rangeIp')}
            value={formState.rangeIp}
            onChange={handleChange}
            fullWidth
            multiline
            error={!!errors.rangeIp}
            helperText={errors.rangeIp}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <FormGroups title='SWA'>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  name='ipv4LanSwa'
                  label="IP de LAN"
                  inputRef={setInputRef('ipv4LanSwa')}
                  value={formState.ipv4LanSwa}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    inputComponent: IPV4MaskInput as any
                  }}
                  error={!!errors.ipv4LanSwa}
                  helperText={errors.ipv4LanSwa}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name='ipv4WanSwa'
                  label="IP de Wan"
                  inputRef={setInputRef('ipv4WanSwa')}
                  value={formState.ipv4WanSwa}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    inputComponent: IPV4MaskInput as any
                  }}
                  error={!!errors.ipv4WanSwa}
                  helperText={errors.ipv4WanSwa}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name='ipv4LoopbackSwa'
                  label="IP de Loopback"
                  inputRef={setInputRef('ipv4LoopbackSwa')}
                  value={formState.ipv4LoopbackSwa}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    inputComponent: IPV4MaskInput as any
                  }}
                  error={!!errors.ipv4LoopbackSwa}
                  helperText={errors.ipv4LoopbackSwa}
                  required
                />
              </Grid>
            </Grid>
          </FormGroups>
        </Grid>
        <Grid item xs={12}>
          <Button className='button' type="submit" variant="contained">
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SwaSwtCircuitForm;