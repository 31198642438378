import { ReactElement } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

type Props = {
  open: boolean;
  onClose: () => void;
  exists?: boolean;
  FormComponent: ReactElement;
};

const CrudModal = ({ open, onClose, exists, FormComponent }: Props) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{exists ? "Editar" : "Adicionar"}</DialogTitle>
      <DialogContent>
        {FormComponent}
      </DialogContent>
      <DialogActions>
        <Button className='button' onClick={onClose}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CrudModal;