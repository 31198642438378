import React, { useState } from 'react';
import { ISwaSwtCircuit } from '../interfaces/ISwaSwtCircuit';
import { Button, Box } from '@mui/material';
import { DataGrid, GridColDef, GridColumnVisibilityModel, GridRowParams, GridToolbar, ptBR } from '@mui/x-data-grid';

type Props = {
  installations: ISwaSwtCircuit[];
  onEdit: (installation: ISwaSwtCircuit) => void;
  onDelete: (id: string) => void;
};

const SwaSwtCircuitTable = ({ installations, onEdit, onDelete }: Props) => {

  const columns: GridColDef<ISwaSwtCircuit>[] = [
    { field: 'id', headerName: 'ID Vantive' },
    { field: 'cliente', headerName: 'Cliente' },
    { field: 'ip', headerName: 'IP' },
    { field: 'ipGatewaySwa', headerName: 'IP Gateway SWA' },
    { field: 'porta', headerName: 'Porta' },
    { field: 'ipv4LanSwt', headerName: 'IP LAN SWT' },
    { field: 'ipv4WanSwt', headerName: 'IP WAN SWT' },
    { field: 'ipv4LoopbackSwt', headerName: 'IP Loopback SWT' },
    { field: 'ipv4LanSwa', headerName: 'IP LAN SWA' },
    { field: 'ipv4WanSwa', headerName: 'IP WAN SWA' },
    { field: 'ipv4LoopbackSwa', headerName: 'IP Loopback SWA' },
    { field: 'cvlan', headerName: 'CVLAN' },
    { field: 'gerenciaVlan', headerName: 'VLAN Gerencia' },
    { field: 'rangeIp', headerName: 'Range IP', width: 200 },
    {
      field: 'actions',
      headerName: 'Ações',
      hideable: false,
      width: 150,
      renderCell: (params) => (
        <Box sx={{ width: '100%' }}>
          <Button onClick={() => onEdit(params.row)}>Editar</Button>
          <Button onClick={() => onDelete(params.row.id!)}>Deletar</Button>
        </Box>
      ),
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      // id: false,
    });

  const handeRowDoubleClick = (params: GridRowParams) => {
    onEdit(params.row);
  };

  return (
    <Box sx={{ height: 500, width: '100%' }}>
      <DataGrid
        rows={installations}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rowHeight={50}
        slots={{
          toolbar: GridToolbar,
        }}
        onRowDoubleClick={handeRowDoubleClick}
      />
    </Box>
  );
};

export default SwaSwtCircuitTable;