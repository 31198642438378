import React, { useState } from 'react';
import { IVivo2Circuit } from '../interfaces/IVivo2Circuit';
import { Button, Box } from '@mui/material';
import { DataGrid, GridColDef, GridColumnVisibilityModel, GridRowParams, GridToolbar, ptBR } from '@mui/x-data-grid';

type Props = {
  installations: IVivo2Circuit[];
  onEdit: (installation: IVivo2Circuit) => void;
  onDelete: (id: string) => void;
};

const Vivo2CircuitTable = ({ installations, onEdit, onDelete }: Props) => {

  const columns: GridColDef<IVivo2Circuit>[] = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'data_agendamento',
      headerName: 'Data Agendamento',
      type: 'dateTime',
      valueGetter: ({ value }) => value && new Date(new Date(value).toUTCString())
    },
    { field: 'cliente', headerName: 'Cliente', minWidth: 200 },
    { field: 'end_cliente', headerName: 'End Cliente', minWidth: 250 },
    { field: 'contato', headerName: 'Contato', minWidth: 150 },
    { field: 'os_tbs', headerName: 'Ordem TBS' },
    { field: 'designador', headerName: 'Designador', minWidth: 150 },
    { field: 'id_vantive', headerName: 'ID Vantive' },
    { field: 'velocidade', headerName: 'Velocidade' },
    { field: 'categoria', headerName: 'Categoria do Serviço', minWidth: 150 },
    { field: 'servico', headerName: 'Tipo de Serviço', minWidth: 150 },
    { field: 'armario', headerName: 'Armário' },
    { field: 'vendor', headerName: 'Vendor' },
    { field: 'cvlan', headerName: 'CVLAN' },
    { field: 'svlan', headerName: 'SVLAN' },
    { field: 'vlan_gerencia', headerName: 'VLAN Gerencia' },
    { field: 'acesso', headerName: 'Acesso / DGO / SW', minWidth: 250 },
    { field: 'gerencia_edd', headerName: 'Gerencia EDD', minWidth: 200 },
    { field: 'equipamentos', headerName: 'Equipamentos', minWidth: 200 },

    //IPV4 LAN
    { field: 'ipv4LanNetworkAddress', headerName: 'IPV4 LAN Rede', minWidth: 150 },
    { field: 'ipv4LanBroadcastAddress', headerName: 'IPV4 LAN Broadcast', minWidth: 150 },
    { field: 'ipv4LanCidr', headerName: 'IPV4 LAN Prefixo', minWidth: 150 },
    { field: 'ipv4LanRouterInterface', headerName: 'IPV4 LAN Interface Router', minWidth: 150 },
    { field: 'ipv4LanFirstClientAddress', headerName: 'IPV4 LAN Primeiro endereço válido', minWidth: 150 },
    { field: 'ipv4LanLastClientAddress', headerName: 'IPV4 LAN Ultimo endereço válido', minWidth: 150 },

    //IPV6 LAN
    { field: 'ipv6LanNetworkAddress', headerName: 'IPV6 LAN Rede', minWidth: 150 },
    { field: 'ipv6LanBroadcastAddress', headerName: 'IPV6 LAN Broadcast', minWidth: 150 },
    { field: 'ipv6LanCidr', headerName: 'IPV6 LAN Prefixo', minWidth: 150 },
    { field: 'ipv6LanRouterInterface', headerName: 'IPV6 LAN Interface Router', minWidth: 150 },
    { field: 'ipv6LanFirstClientAddress', headerName: 'IPV6 LAN Primeiro endereço válido', minWidth: 150 },
    { field: 'ipv6LanLastClientAddress', headerName: 'IPV6 LAN Ultimo endereço válido', minWidth: 150 },

    //IPV4 WAN
    { field: 'ipv4WanNetworkAddress', headerName: 'IPV4 WAN Rede', minWidth: 150 },
    { field: 'ipv4WanBroadcastAddress', headerName: 'IPV4 WAN Broadcast', minWidth: 150 },
    { field: 'ipv4WanCidr', headerName: 'IPV4 WAN Prefixo', minWidth: 150 },
    { field: 'ipv4WanRouterInterface', headerName: 'IPV4 WAN Interface Cliente', minWidth: 150 },
    { field: 'ipv4WanVivoRouterInterface', headerName: 'IPV4 WAN Interface Vivo', minWidth: 150 },

    //IPV6 WAN
    { field: 'ipv6WanNetworkAddress', headerName: 'IPV6 WAN Rede', minWidth: 150 },
    { field: 'ipv6WanBroadcastAddress', headerName: 'IPV6 WAN Broadcast', minWidth: 150 },
    { field: 'ipv6WanCidr', headerName: 'IPV6 WAN Prefixo', minWidth: 150 },
    { field: 'ipv6WanRouterInterface', headerName: 'IPV6 WAN Interface Cliente', minWidth: 150 },
    { field: 'ipv6WanVivoRouterInterface', headerName: 'IPV6 WAN Interface Vivo', minWidth: 150 },

    // IP LOOPBACK
    { field: 'ipLoopbackNetworkAddress', headerName: 'IP LOOPBACK Endereço Router', minWidth: 150 },
    { field: 'ipLoopbackCidr', headerName: 'IP LOOPBACK Prefixo', minWidth: 150 },

    { field: 'description', headerName: 'Descrição', maxWidth: 250 },
    {
      field: 'actions',
      headerName: 'Ações',
      hideable: false,
      minWidth: 200,
      renderCell: (params) => (
        <div>
          <Button onClick={() => onEdit(params.row)}>Editar</Button>
          <Button onClick={() => onDelete(params.row.id!)}>Deletar</Button>
        </div>
      ),
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      id: false,
    });

  const handeRowDoubleClick = (params: GridRowParams) => {
    onEdit(params.row);
  };

  return (
    <Box sx={{ height: 500, width: '100%' }}>
      <DataGrid
        rows={installations}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rowHeight={50}
        slots={{
          toolbar: GridToolbar,
        }}
        onRowDoubleClick={handeRowDoubleClick}
      />
    </Box>
  );
};

export default Vivo2CircuitTable;